import * as React from "react"
import { graphql } from "gatsby"
import "../css/haiku.scss"

import JSONData from "../../content/fun/haiku.json"


const Haiku = ({year, haiku}) => {
  return (
    <div className="haiku">
      <h1 className="haiku-year">{year}</h1>
      <div className="haiku-text">
        {
          haiku.split('\n').map((line) => {
            return <p className="haiku-line">{line}</p>
          })
        }
      </div>
    </div>

  )
}

const HaikuPage = ({ data, location }) => {
  data = JSONData;
  data.sort((a, b) => b.year - a.year);

  return (
    <div id="haiku-container">
      <h1>Years in Haiku</h1>
      <p>(acording to chatGPT)</p>
    {
      data.map((data) => {
        return <Haiku year={data['year']} haiku={data['haiku']} />
      })
    }
    </div>
  )
}

export default HaikuPage